import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import toast, {Toaster} from 'react-hot-toast';

import { userId } from "../../dynamic/token";

const Details = (props) => {

  const { showModal, toggleModal, index, changeDetails} = props;

  const [value, setValue] = useState('');
  
  const handleSubmit = async (e) => {
    toggleModal();
    e.preventDefault();
    changeDetails(value, index)
    setValue('');
  }


  return (
      <Modal show={showModal} onHide={toggleModal} >
      <Modal.Header style={{ background: "#1f2937" }}>
      <Modal.Title style={{ color: "white" }}>Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <form onSubmit={handleSubmit} id='addForm'>

 
      <div className="mb-3 row">
      <label className="col-sm-2 col-form-label">Details</label>
      <div className="col-sm-10">
      <input type="text" onChange={(e) => setValue(e.target.value)} value={value} className="form-control" />
      </div>
      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={toggleModal}>
          Close
        </button>
        <input type="submit" className="btn btn-primary" value="Add Details"></input>
      </div>


      </form>
      </Modal.Body>
      </Modal>
      )
}


export default Details;