import React, { useState, useEffect, useContext  } from 'react';

import StockActivityTable from './stockActivityTable';


function StockActivityHeader(props) {
  
 
  
  const [search, setSearch] = useState('');

  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
 
  useEffect(() => {

    const today = new Date().toISOString().substr(0, 10);

    document.getElementById('fromDate').value = today;
    document.getElementById('toDate').value = today;

    setFromDate(today);
    setToDate(today);

  }, []);


  return (
    <div className='card' style={{userSelect: 'none'}}>

    
        <div>
        
        <input type="search" className="form-control search" placeholder="Search" onChange={(e) => setSearch(e.target.value)}  ></input>

      <div className="col-sm-10">
      <input type="date" name="fromDate" id="fromDate" onChange={(e) => setFromDate(e.target.value)}  className="form-control date" ></input>
      <input type="date" name="toDate" id="toDate" onChange={(e) => setToDate(e.target.value)}  className="form-control date" ></input>
      </div>


      </div>
  
      <StockActivityTable
        isActive={'true'}
        search={search}
        fromDate={fromDate}
        toDate={toDate}
         />
      

    </div>
  )
}

export default StockActivityHeader;
