import React, { useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import toast, {Toaster} from 'react-hot-toast';

import { api } from "../../../dynamic/api";

import { DataContext } from '../../../getData/getData';

const CategoryEdit = (props) => {

  const {showModal, toggleModal, idRow, refreshTable, tittle} = props;

  const {refreshCategory} = useContext(DataContext);

  
  useEffect(() => {

    let dataArray = transformObject(idRow);

    for (const prop in dataArray) {
      const field = document.getElementById(prop);
  
      if (field) {
        field.value = dataArray[prop];
      }
    } 

  }, [idRow]);

  function transformObject(obj) {
    for (let key in obj) {
      if (typeof obj[key] === 'object') {
        const nestedObj = obj[key];
        for (let nestedKey in nestedObj) {
          const newKey = `${key}${nestedKey.charAt(0).toUpperCase()}${nestedKey.slice(1)}`;
          obj[newKey] = nestedObj[nestedKey];
        }
        delete obj[key];
      }
    }
    return obj;
  }
  

  const handleSubmit = async (e) => {
    toggleModal();
    e.preventDefault();
    const form = document.getElementById("editGroup");
      
    const formData = new FormData(form);
    formData.append("id", idRow.id)
    const data = Object.fromEntries(formData);
    
    let res = await api.post(`/category/edit`, JSON.stringify(data))
    if(res.data.status){
      toast.success(res.data.message);
    }else{
      toast.error(res.data.message);
    }

    refreshCategory();
    form.reset();
    refreshTable();
  }

 
    return (
      <Modal show={showModal} onHide={toggleModal}>
        <Modal.Header style={{ background: "#1f2937" }}>
          <Modal.Title style={{ color: "white" }}>Edit {tittle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <form onSubmit={handleSubmit} encType="multipart/form-date" id="editGroup">
          <div className="modal-body">

          <div className="mb-3 row">
          <label className="col-sm-2 col-form-label">Name</label>
          <div className="col-sm-10">
          <input type="text" name="name" id="name" className="form-control"></input>
          </div>
          </div>

          <div className="mb-3 row">
          <label  class="col-sm-2 col-form-label">Details</label>
          <div className="col-sm-10">
          <textarea className="form-control" name="details" id="details"></textarea>
          </div></div>

          </div>
  
          <div className="modal-footer">
          <button type="button" className="btn btn-secondary" onClick={toggleModal}>
          Close
          </button>
          <input type="submit" className="btn btn-primary" value="Save Data"></input>
          </div>
          </form>
        </Modal.Body>
      </Modal>
    );
  };
  
  export default CategoryEdit;
