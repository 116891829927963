import React, { useCallback, useEffect, useMemo, useState, memo, useRef } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import '@ag-grid-enterprise/excel-export';

import moment from 'moment';

import AdminOrderAdd from './crud/adminOrderAdd';
import AdminOrderEdit from './crud/adminOrderEdit';
import AdminOrderTrash from './crud/adminOrderTrash';
import Sales from '../sales/sales';


import { userId } from "../dynamic/token";
import { userRole } from "../dynamic/token";
import { api } from "../dynamic/api";
import { printPaper } from "../dynamic/print";

import toast from 'react-hot-toast';

import edit_png from '../images/edit.png';
import delete_png from '../images/trash.png';
import cart_png from '../images/set.png';

const dataFormat = require("../dynamic/date");


function OrderTable(props) {

  const { isActive, isBeirut, showAddModal, setShowAddModal, fromDate, toDate, print, exportExcel, cityData, search, setProfit } = props;

  const [showEditModal, setShowEditModal] = useState(false);
  const [showTrashModal, setShowTrashModal] = useState(false);
  const [showSalesModal, setShowSalesModal] = useState(false);

  const [idRow, setIdRow] = useState([]);

  const [columnDefs, setColumnDefs] = useState([]);
  const [gridApi, setGridApi] = useState(null);

  const [searchState, setSearchState] = useState(false);
  const timeoutRef = useRef(null);


//-----------------------------------------------------------------


const changeStatus = async(data) => {
  let res = await api.post(`/order/changeStatus`, JSON.stringify(data))
  if(res.data.status){
    toast.success(res.data.message);
  }else{
    toast.error(res.data.message);
  }
  refreshTable();
}


useEffect(() => { 
  if(print != null){
    if(gridApi.api){
    const selectedRows = gridApi.api.getSelectedRows();
    if(selectedRows.length > 0){
      const idArray = gridApi.api.getSelectedRows().map(obj => obj.id);
      let data = {idArray: idArray, status: 'processed'}
      changeStatus(data);
      printPaper(selectedRows);
    }else{
      toast.error('Select rows to Print')
    }
    }
  }
}, [print]);

useEffect(() => { 
  if(exportExcel != null){
    if(gridApi.columnApi){

      if (gridApi.api.getSelectedRows().length > 0) {

      

      const dateNow = new Date(dataFormat.getDateFormatted());

      gridApi.columnApi.setColumnVisible('createdAt', false);
      gridApi.columnApi.setColumnVisible('created.name', false);
      gridApi.columnApi.setColumnVisible('edit', false);
      gridApi.columnApi.setColumnVisible('delete', false);
      gridApi.columnApi.setColumnVisible('status', false);
      gridApi.columnApi.setColumnVisible('cart', false);
      gridApi.columnApi.setColumnVisible('profit', false);

      gridApi.columnApi.setColumnVisible('shipper', true);
      gridApi.columnApi.setColumnVisible('lastName', true);
      gridApi.columnApi.setColumnVisible('companyName', true);
      gridApi.columnApi.setColumnVisible('mobileCountryCode', true);
      gridApi.columnApi.setColumnVisible('country', true);
      gridApi.columnApi.setColumnVisible('street', true);
      gridApi.columnApi.setColumnVisible('building', true);
      gridApi.columnApi.setColumnVisible('floor', true);
      gridApi.columnApi.setColumnVisible('weight', true);
      gridApi.columnApi.setColumnVisible('nop', true);
      gridApi.columnApi.setColumnVisible('CODCurrency', true);
      gridApi.columnApi.setColumnVisible('CODCurrency2', true);


// check if any rows are selected

  // call the exportDataAsExcel method to export the selected rows as an Excel file

      gridApi.api.exportDataAsExcel({ onlySelected: true, fileName: dateNow+'.xlsx' });

      const idArray = gridApi.api.getSelectedRows().map(obj => obj.id);
      let data = {idArray: idArray, status: 'processed'}
      changeStatus(data);
      

      gridApi.columnApi.setColumnVisible('createdAt', true);
      gridApi.columnApi.setColumnVisible('created.name', true);
      gridApi.columnApi.setColumnVisible('edit', true);
      gridApi.columnApi.setColumnVisible('delete', true);
      gridApi.columnApi.setColumnVisible('status', true);
      gridApi.columnApi.setColumnVisible('cart', true);
      gridApi.columnApi.setColumnVisible('profit', true);
      

      gridApi.columnApi.setColumnVisible('shipper', false);
      gridApi.columnApi.setColumnVisible('lastName', false);
      gridApi.columnApi.setColumnVisible('companyName', false);
      gridApi.columnApi.setColumnVisible('mobileCountryCode', false);
      gridApi.columnApi.setColumnVisible('country', false);
      gridApi.columnApi.setColumnVisible('street', false);
      gridApi.columnApi.setColumnVisible('building', false);
      gridApi.columnApi.setColumnVisible('floor', false);
      gridApi.columnApi.setColumnVisible('weight', false);
      gridApi.columnApi.setColumnVisible('nop', false);
      gridApi.columnApi.setColumnVisible('CODCurrency', false);
      gridApi.columnApi.setColumnVisible('CODCurrency2', false);

      } else {
        toast.error('Select rows to Export')
      }
    }
  }
}, [exportExcel]);

  const addModal = () => {
    setShowAddModal(prevshowAddModal => !prevshowAddModal);
  }

  const editModal = (e) => {
    if (e != undefined) {
      setIdRow(e);
    }
    setShowEditModal(prevshowEditModal => !prevshowEditModal);
  }

  const salesModal = (e) => {
    if (e != undefined) {
      setIdRow(e);
    }
    setShowSalesModal(!showSalesModal);
  }

  const trashModal = (e) => {
    if (e != undefined) {
      setIdRow(e);}
      setShowTrashModal(prevshowTrashModal => !prevshowTrashModal);
  }

 
  function dateFormatter(e) {
    const date = new Date(e.value);
    const formattedDate = moment.utc(date).format('DD-MM-YY hh:mm A');
    return formattedDate;
  }

 
  useEffect(() => {

  let newColumnDefs = [
    { field: 'id', headerName: 'Ref', width: 80},
    { field: 'profit', headerName: 'PFT', width: 80},
    { field: 'shipper', headerName: 'Shipper', hide: true},
    { field: 'createdAt', valueFormatter: dateFormatter, width: 180},
    { field: 'firstName', headerName: 'First Name'},
    { field: 'lastName', headerName: 'Last Name', hide: true},
    { field: 'companyName', headerName: 'Company Name', hide: true},
    { field: 'mobileCountryCode', headerName: 'MobileCountryCode', hide: true},
    { field: 'mobile', headerName: 'Mobile', width: 120},
    { field: 'country', headerName: 'Country',  hide: true},
    { field: 'street', headerName: 'Street', hide: true},
    { field: 'building', headerName: 'Building',  hide: true},
    { field: 'floor', headerName: 'Floor', hide: true},
    { field: 'city.name', headerName: 'City', width: 150},
    { field: 'landmark', headerName: 'Landmark', width: 400},
    { field: 'specialInstructions', headerName: 'Special Instructions' , width: 400},
    { field: 'weight', headerName: 'Weight', hide: true},
    { field: 'nop', headerName: 'nop', hide: true},
    { field: 'usd', headerName: 'COD Amount', width: 100},
    { field: 'CODCurrency', hide: true},
    { field: 'lbp', headerName: 'COD Amount 2', width: 100},
    { field: 'CODCurrency2', headerName: 'COD Currency 2',  hide: true},
    { field: 'created.name', headerName: 'User',   width: 150},
    { field: 'status', headerName: 'Status', width: 150},
  ];



  setColumnDefs(newColumnDefs); 

  if(isActive == 'true'){
    
  newColumnDefs.push(
  
    {field: 'cart', headerName: 'Cart', width: 85, sortable: false, cellRenderer: ({ data }) => (
      <input type="image" src={cart_png} onClick={e => salesModal(data)} width={17} />)},
      
    {field: 'edit', headerName: 'Edt', width: 80, sortable: false, cellRenderer: ({ data }) => (
    <input type="image" src={edit_png} onClick={e => editModal(data)} width={17} />)},
    
    {field: 'delete', headerName: 'Del', width: 80, sortable: false, cellRenderer: ({ data }) => (
    <input type="image" src={delete_png} onClick={e => trashModal(data)} width={18} />)},
    );
  }

  if (gridApi) {
    gridApi.api.setServerSideDatasource(datasource);
     }

  }, [isActive, isBeirut, fromDate, toDate]);


  const defaultColDef = useMemo(() => ({
    filter: false,
    sortable: true,
    resizable: true,
    wrapText: true,
    autoHeight: true,
  }), []);


  const onGridReady = (params) => {
    setGridApi(params);
    params.api.setServerSideDatasource(datasource);
  }

  const refreshTable = () => {
  gridApi.api.refreshServerSide();

  }

  var datasource = useMemo(() => {
    return {
      getRows(params) {
      const { startRow, endRow, filterModel, sortModel } = params.request;
         
      
      let data = {
          startRow: startRow,
          endRow: endRow,
          isActive: isActive,
          userId: userId,
          fromDate: fromDate,
          toDate: toDate,
          userRole: userRole,
          isBeirut: isBeirut,
        };

        //Sorting
        if (sortModel.length) {
        const { colId, sort } = sortModel[0]
        data.sortField = colId;
        data.sortDirection = sort;
      }

            
        api.get(`/order/getAdminall`, {params: data}).then(response => {
        params.successCallback(response.data.data, response.data.totalRecords);
        setProfit(response.data.totalProfit)
        if(!response.data.status){
        toast.error('Failed to get Data')
        }
      })
    }
  }
}, [isActive, isBeirut, toDate, fromDate]);


useEffect(() => { 
 
  if (search.length > 0) {
    setSearchState(true);
      
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
    
        var datasource = {
          getRows(params) {

            let data = {
              isActive: isActive,
              userId: userId,
              fromDate: fromDate,
              toDate: toDate,
              userRole: userRole,
              isBeirut: isBeirut,
              search: search,
            };

            api.get(`/order/getAdminall`, {params: data}).then(response => {
              params.successCallback(response.data.data, response.data.totalRecords);
              setProfit(response.data.totalProfit)
              if(!response.data.status){
              toast.error('Failed to get Data')
              }
            })
          }
        }
        gridApi.api.setServerSideDatasource(datasource);
    }, 1000);
  }else{
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    if (searchState) onGridReady(gridApi);
 
  }
}, [search]);
  
  return (
    <div>     
    
    <AdminOrderAdd   showModal={showAddModal}   toggleModal={addModal}   refreshTable={refreshTable} cityData={cityData} isBeirut={isBeirut}/>
    <AdminOrderEdit  showModal={showEditModal}  toggleModal={editModal}  refreshTable={refreshTable} idRow={idRow} cityData={cityData} isBeirut={isBeirut} />
    <AdminOrderTrash showModal={showTrashModal} toggleModal={trashModal} refreshTable={refreshTable} idRow={idRow}  />
    <Sales           showModal={showSalesModal} toggleModal={salesModal} refreshTable={refreshTable} idRow={idRow}  />
   
      
      <div className="ag-theme-alpine" style={{ width: '100%', height: '75vh' }} >
        <AgGridReact
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        animateRows={true}
        rowModelType={'serverSide'}
        onGridReady={onGridReady}
        serverSideInfiniteScroll={true}
        pagination={true}
        paginationPageSize={200}
        cacheBlockSize={200}
        rowSelection={'multiple'}
        />
      </div>

      
    </div>
  );
}

export default OrderTable;
