import React, {  useState, useEffect, createContext} from 'react';
import { Route, Routes, useNavigate, NavLink} from 'react-router-dom';


import CategoryHeader from './category/categoryHeader';
import ProductHeader from './product/productHeader';
import StockHeader from './stock/stockHeader';
import StockActivityHeader from './stockActivity/stockActivityHeader';

function ProductTab(props) {

  const navigate = useNavigate();

  useEffect(() => {

    navigate('/productTab/products');

  }, []);


  return (
    <div className="card" style={{paddingTop : '0px'}}>
      
      <div>
        <NavLink to="products" className={({isActive}) => (isActive ? "active-user" : 'none')}>
          <div className="tab-blue">
            <i className='bx product bx-group bx-md'></i>
            <span className="tab-text">Products</span>
          </div>
        </NavLink>
        <NavLink to="categories" className={({isActive}) => (isActive ? "active-user" : 'none')}>
          <div className="tab-cayan ">
            <i className='bx bx-category-alt  bx-md'></i>
            <span className="tab-text">Categories</span>
          </div>
        </NavLink>
        
        <NavLink to="stock" className={({isActive}) => (isActive ? "active-user" : 'none')}>
          <div className="tab-purple ">
            <i className='bx bx-box  bx-md'></i>
            <span className="tab-text">Stock Control</span>
          </div>
        </NavLink>

        <NavLink to="stockActivity" className={({isActive}) => (isActive ? "active-user" : 'none')}>
          <div className="tab-yellow ">
            <i className='bx bx-box  bx-md'></i>
            <span className="tab-text">Stock Activity</span>
          </div>
        </NavLink>
      </div>

 
      <Routes>

        <Route path='products' element= {<ProductHeader/>}/>
        <Route path='categories' element= {<CategoryHeader/>}/>
        <Route path='stock' element= {<StockHeader/>}/>
        <Route path='stockActivity' element= {<StockActivityHeader/>}/>
        
      </Routes>

    </div>
  );
}

export default ProductTab;
