import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import toast, {Toaster} from 'react-hot-toast';

import { api } from "../../../dynamic/api";
import { userId } from "../../../dynamic/token";



const StockTransfer = (props) => {

  const { showModal, toggleModal, refreshTable, idRow } = props;

  const handleSubmit = async (e) => {
    toggleModal();
    e.preventDefault();
    const form = document.getElementById("addForm");
      
    const formData = new FormData(form);
    const data = Object.fromEntries(formData);

    data['id'] = idRow.id;  
    data['createdBy'] = userId;  
    
    let res = await api.post(`/stock/transfer`, JSON.stringify(data))
    if(res.data.status){
      toast.success(res.data.message);
    }else{
      toast.error(res.data.message);
    }
    form.reset();
    refreshTable();
  }


  return (
      <Modal show={showModal} onHide={toggleModal} >
      <Modal.Header style={{ background: "#1f2937" }}>
      <Modal.Title style={{ color: "white" }}>Transfer Stock</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <form onSubmit={handleSubmit} id='addForm'>

      <div className="modal-body">


      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label">Quantity</label>
        <div className="col-sm-10">
          <input type="number" step="any" name="quantity" id="quantity" className="form-control"></input>
        </div>
      </div>

      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label">Details</label>
        <div className="col-sm-10">
          <textarea type="text" step="any" name="details" id="details" className="form-control"></textarea>
        </div>
      </div>


      <div className="mb-3 row">
      <label className="col-sm-2 col-form-label">Stock</label>
      <div className="col-sm-10">
        <select name='stock' className="form-control" id= 'stock' required>
        
            <option value='stock'> Mark to Adam </option>
            <option value='stock2'> Adam to Mark </option>
        
        </select>
        </div>
      </div>

  

</div>

      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={toggleModal}>
          Close
        </button>
        <input type="submit" className="btn btn-primary" value="Save Data"></input>
      </div>
      </form>
      </Modal.Body>
      </Modal>
      )
}


export default StockTransfer;