import React, { useState, useEffect, useContext  } from 'react';

import CategoryTable from './categoryTable';


function CategoryHeader(props) {

  const [showAddModal, setShowAddModal] = useState(null);

  const [isActive, setIsActive] = useState('true');
  const [search, setSearch] = useState('');


  const addModal = () => {
    setShowAddModal(!showAddModal);
  }

  
  



  
  return (
    <div className='card'>

      
        <div>
        
        <input type="search" className="form-control search" placeholder="Search" onChange={(e) => setSearch(e.target.value)}  ></input>



      <select className="form-control dropDown" onChange={(e) => setIsActive(e.target.value)} >
      <option value='true' >Active</option>
      <option value='false'>Deleted</option>
      </select>


      <button onClick={addModal} type="button" className="btn btn-add"> Add  </button>



      </div>

 
      <CategoryTable
      showAddModal={showAddModal}
      addModal={addModal}
      isActive={isActive} 
      search={search} />
      

    </div>
  )
}

export default CategoryHeader;
