import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import toast, {Toaster} from 'react-hot-toast';

import { api } from "../../../dynamic/api";
import { DataContext } from '../../../getData/getData';


const ProductAdd = (props) => {

  const { showModal, toggleModal, refreshTable } = props;
  const {categoryData, refreshProduct} = useContext(DataContext);

  const handleSubmit = async (e) => {
    toggleModal();
    e.preventDefault();


    const form = document.getElementById("addForm");
          
    const formData = new FormData(form);


    let res = await api.post(`/Product/add`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if(res.data.status){
      toast.success(res.data.message);
    }else{
      toast.error(res.data.message);
    }
    refreshProduct();
    form.reset();
    refreshTable();
  }

  return (
      <Modal show={showModal} onHide={toggleModal} >
      <Modal.Header style={{ background: "#1f2937" }}>
      <Modal.Title style={{ color: "white" }}>Add Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>

      <form  onSubmit={handleSubmit} id='addForm'>

      <div className="modal-body">

    
      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label">Barcode</label>
        <div className="col-sm-10">
          <input type="text" name="barcode" id="barcode" className="form-control"></input>
        </div>
      </div>

      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label">Name</label>
        <div className="col-sm-10">
          <input type="text" name="name" id="name" className="form-control"></input>
        </div>
      </div>

      <div className="mb-3 row">
      <label className="col-sm-2 col-form-label">Image</label>
      <div className="col-sm-10">
      <input type="file" name="image"  />
      </div>
      </div>


      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label">Mark Stock</label>
        <div className="col-sm-10">
          <input type="number" step="any" name="stock" id="stock" className="form-control"></input>
        </div>
      </div>

      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label">Adam Stock</label>
        <div className="col-sm-10">
          <input type="number" step="any" name="stock2" id="stock2" className="form-control"></input>
        </div>
      </div>

      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label">Cost</label>
        <div className="col-sm-10">
          <input type="number" step="any" name="cost" id="cost" className="form-control"></input>
        </div>
      </div>

      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label">Price</label>
        <div className="col-sm-10">
          <input type="number" step="any" name="price" id="price" className="form-control"></input>
        </div>
      </div>

      <div className="mb-3 row">
      <label className="col-sm-2 col-form-label">Category</label>
      <div className="col-sm-10">
        <select name='categoryId' className="form-control" id= 'categoryId' required>
          {categoryData.map((option, index) => (
            <option key={index} value={option.id}> {option.parent ? option.parent.name + ': ' : ''}   {option.name}</option>
          ))}
        </select>
        </div>
      </div>

</div>

      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={toggleModal}>
          Close
        </button>
        <input type="submit" className="btn btn-primary" value="Save Data"></input>
      </div>
      </form>
      </Modal.Body>
      </Modal>
      )
}


export default ProductAdd;