import React, { useState, useEffect, useContext  } from 'react';

import OrderTable from './adminOrderTable';

import { userId } from "../dynamic/token";
import { api } from "../dynamic/api";


import toast from 'react-hot-toast';

function AdminOrderHeader(props) {

  

  const { type, changeNumOfOrders} = props;
  
 
  const [showAddModal, setShowAddModal] = useState(null);

  const [isActive, setIsActive] = useState('true');
  const [isBeirut, setIsBeirut] = useState('false');
  const [profit, setProfit] = useState(0);

  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  const [print, setPrint] = useState(null);
  const [exportExcel, setExportExcel] = useState(null);

  const [cityData, setCityData] = useState([]);

  const [search, setSearch] = useState('');


  useEffect(() => {

    const today = new Date().toISOString().substr(0, 10);

    document.getElementById('fromDate').value = today;
    document.getElementById('toDate').value = today;

    setFromDate(today);
    setToDate(today);

    
  }, []);



  useEffect(() => {
    api.get(`/city/getAll`).then((results) => {
    if(results){
      setCityData(results.data.data);
    }}).catch((error) => {
    toast.error(''+error); }); 
  }, []);


  const togglePrint = ( ) => {
   setPrint(!print);
  }

  const toggleExport = ( ) => {
    setExportExcel(!exportExcel);
   }

  const addModal = () => {
    setShowAddModal(prevshowAddModal => !prevshowAddModal);
  }

  const Search = (e) => {

  };
  



  
  return (
    <div className='card'>

        
        <span className='tittle' >Admin Orders</span>
        <span></span>
        <br/>
      
        <div>
        
        <input type="search" className="form-control search" placeholder="Search" onChange={(e) => setSearch(e.target.value)}  ></input>



      <select className="form-control dropDown" onChange={(e) => setIsActive(e.target.value)} >
      <option value='true' >Active</option>
      <option value='false'>Deleted</option>
      </select>

      <select  className="form-control dropDown" onChange={(e) => setIsBeirut(e.target.value)} >
      <option value='false'>Sky Net</option>
      <option value='true'>Beirut</option>
      </select>

   


      <button onClick={addModal} type="button" className="btn btn-add"> Add  </button>

      {isBeirut == 'true'  && (
      <button onClick={togglePrint} type="button" className="btn btn-add">
      Print
      </button>
      )}


      {isBeirut == 'false'  && (
      <button onClick={toggleExport} type="button" className="btn btn-add">
      Export
      </button>
      )}



      <div className="col-sm-10">
      <input type="date" name="fromDate" id="fromDate" onChange={(e) => setFromDate(e.target.value)}  className="form-control date" ></input>
      <input type="date" name="toDate" id="toDate" onChange={(e) => setToDate(e.target.value)}  className="form-control date" ></input>
      </div>

      
      <span className="header-total">
      {profit} $
      </span>


      </div>

      {cityData.length > 0  && (
      <OrderTable
      showAddModal={showAddModal}
      setShowAddModal={setShowAddModal}
      isActive={isActive} 
      isBeirut={isBeirut}
      fromDate={fromDate}
      toDate={toDate}
      print={print}
      exportExcel={exportExcel}
      cityData={cityData}
      search={search}
      setProfit={setProfit}
       />
      )}

    </div>
  )
}

export default AdminOrderHeader;
