import React, {  useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { api } from "../dynamic/api";

import Cookies from 'js-cookie';
import './menu.css';
import toast from 'react-hot-toast';



import OrderHeader from '../order/orderHeader';
import AdminOrderHeader from '../adminOrder/adminOrderHeader';
import ProductTab from '../productTab/productTab';

import Settings from '../settings/settings';


let {userName, userRole, userPermission} = require("../dynamic/token");



  function Menu (){

   
   const navigate = useNavigate();

    const [toggle, setToggle] = useState('sidebar');
    const [tog, setTog] = useState(1);


    const dashboard = userPermission.find(obj => obj.menuId === 1).enabled;
    const orders = userPermission.find(obj => obj.menuId === 2).enabled;
    const adminOrder = userPermission.find(obj => obj.menuId === 3).enabled;
    const productTab = userPermission.find(obj => obj.menuId === 4).enabled;
    const setting = userPermission.find(obj => obj.menuId === 5).enabled;
    
    const dev = false;

  


     const toggles = () => {
      if(tog == 1){
        setTog(0);
        setToggle('sidebar open')
      }
      else {
        setTog(1);
        setToggle('sidebar')
      }
    }

    const signOut = () => {
      Cookies.remove('token');
      toast.success('Logged out');
      navigate('/login', { replace: true });
    }

  return (
  <div>
  <div className={toggle}>
    <div className="logo-details">
      <i className='bx bxl-c-plus-plus icon'></i>
        <div className="logo_name">Pos Sys</div>
        <i className='bx bx-menu' id="btn" onClick={toggles} ></i>
    </div>
    <ul className="nav-list">

    {dashboard &&<li>
        <a href="#">
          <i className='bx bx-grid-alt'></i>
          <span className="links_name">Dashboard</span>
        </a>
         <span className="tooltip">Dashboard</span>
      </li>}

   

     {orders &&<li>
     <NavLink to="order" className={({isActive}) => (isActive ? "active-style" : 'none')}>
         <i className='bx bx-group'  ></i>
         <span className="links_name">Orders</span>
         </NavLink>
       <span className="tooltip">Orders</span>
     </li>}

     
     {adminOrder &&<li>
     <NavLink to="adminOrder" className={({isActive}) => (isActive ? "active-style" : 'none')}>
          <i className='bx bx-cart-alt' ></i>
         <span className="links_name">Orders</span>
         </NavLink>
       <span className="tooltip">Orders</span>
     </li>}

     {productTab &&<li>
     <NavLink to="productTab" className={({isActive}) => (isActive ? "active-style" : 'none')}>
           <i className='bx bx-box  '></i>
         <span className="links_name">Product</span>
         </NavLink>
       <span className="tooltip">Product</span>
     </li>}


     
     {setting &&<li>
     <NavLink to="settings" className={({isActive}) => (isActive ? "active-style" : 'none')}>
         <i className='bx bx-cog' ></i>
         <span className="links_name">Settings</span>
         </NavLink>
       <span className="tooltip">Settings</span>
     </li>}     

     
     {dev &&<li>
     <NavLink to="dev" className={({isActive}) => (isActive ? "active-style" : 'none')}>
         <i className='bx bx-home' ></i>
         <span className="links_name">Dev Panel</span>
         </NavLink>
       <span className="tooltip">Dev Panel</span>
     </li>}
     
     <li className="profile">
         <div className="profile-details">
           <div className="name_job">
             <div className="name">User: {userName}</div>
             <div className="job">Role: {userRole}</div>
             
           </div>
         </div>
         <button onClick={signOut}>
         <i className='bx bx-log-out ' id="log_out" ></i>
         </button>
     </li>
    </ul>
  </div>

  <section className="home-section">
  
  
  <Routes>
  <Route path='order' element={<OrderHeader/> }/>
  <Route path='adminOrder' element={<AdminOrderHeader /> }/>
  <Route path='productTab/*' element={<ProductTab /> }/>
  <Route path='settings' element={<Settings/>}/>
  </Routes>
  
  </section>

   </div>
            
        );
    }

 
export default Menu;