import React from 'react';
import { Modal } from 'react-bootstrap';
import toast, {Toaster} from 'react-hot-toast';

const api = require("../../dynamic/api").api;

const OrderTrash = (props) => {
const { showModal, toggleModal, idRow, refreshTable } = props;


  const trigger = async () => {
    let data = {id: idRow.id}
    let res = await api.post(`/order/trash`, JSON.stringify(data));
    if(res.data.status){
      toast.success(res.data.message);
    }else{
      toast.error(res.data.message);
    }
        
    toggleModal();
    refreshTable();
  }
  

    
  return (
    <Modal show={showModal} onHide={toggleModal} className='Delete_modal'>
      <Modal.Header style={{ background: "#1f2937" }}>
        <Modal.Title style={{ color: "white" }}>Trash Order</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      Order will be Moved to trash ,
      are you sure ?
      
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-secondary" onClick={toggleModal}>NO</button>
        <input type="button" className="btn btn-primary" onClick={trigger} value="Yes !!"></input>
      </Modal.Footer>
    </Modal>
  );

  }
export default OrderTrash;
