import React, { useCallback, useEffect, useMemo, useState, memo, useRef } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import ProductAdd from './crud/productAdd';
import ProductEdit from './crud/productEdit';
import ProductTrash from './crud/productTrash';
import ProductRecover from './crud/productRecover';

import { api } from "../../dynamic/api";
import toast from 'react-hot-toast';

import edit_png from '../../images/edit.png';
import delete_png from '../../images/trash.png';
import recover_png from '../../images/rotate.png';


function ProductTable(props) {

  const { isActive, showAddModal, addModal, search } = props;

  const [showEditModal, setShowEditModal] = useState(false);
  const [showTrashModal, setShowTrashModal] = useState(false);
  const [showRecoverModal, setShowRecoverModal] = useState(false);

  const [idRow, setIdRow] = useState([]);

  const [columnDefs, setColumnDefs] = useState([]);
  const [gridApi, setGridApi] = useState(null);

  const [searchState, setSearchState] = useState(false);


  const timeoutRef = useRef(null);

//-----------------------------------------------------------------



  const editModal = (e) => {
    if (e != undefined) {
      setIdRow(e);
    }
    setShowEditModal(!showEditModal);
  }

  const trashModal = (e) => {
    if (e != undefined) {
      setIdRow(e);}
      setShowTrashModal(!showTrashModal);
  }

  const recoverModal = (e) => {
    if (e != undefined) {
      setIdRow(e);}
      setShowRecoverModal(!showRecoverModal);
  }

 

  
  

  useEffect(() => {

  let newColumnDefs = [
    { field: 'id' , hide: true},
    { field: 'barcode',  cellStyle: { display: 'flex', alignItems: 'center'}},
    { field: 'name',  cellStyle: { display: 'flex', alignItems: 'center'}},
    { field: 'stock',  cellStyle: { display: 'flex', alignItems: 'center'}},
    { field: 'stock2',  cellStyle: { display: 'flex', alignItems: 'center'}},
    { field: 'cost',  cellStyle: { display: 'flex', alignItems: 'center'}},
    { field: 'price',  cellStyle: { display: 'flex', alignItems: 'center'}},
    { field: 'category.name', headerName: 'Category' , sortable: false,  cellStyle: { display: 'flex', alignItems: 'center'}},
  ];

  setColumnDefs(newColumnDefs);


  newColumnDefs.splice(1, 0, 
    {
      field: 'edit',
      headerName: 'IMG',
      flex: 1.3,
      sortable: false,
      cellRenderer: ({ data }) => (
        <img src={`data:image/png;base64,${data.imageFile}`} alt="Product Image" width={150} />

      ),
  });


  if(isActive == 'true'){
 

  newColumnDefs.push(

    {field: 'edit', headerName: 'Edt', flex: 0.6, sortable: false, cellStyle: { display: 'flex', alignItems: 'center'}, cellRenderer: ({ data }) => (
      <input type="image" src={edit_png} onClick={e => editModal(data)} width={17} />)},
    
    {field: 'delete', headerName: 'Del', flex: 0.6, sortable: false, cellStyle: { display: 'flex', alignItems: 'center'}, cellRenderer: ({ data }) => (
    <input type="image" src={delete_png} onClick={e => trashModal(data)} width={18} />)},
    );
  }else{
    newColumnDefs.push(      
      {field: 'recover', headerName: 'Rec', flex: 0.5, sortable: false, cellStyle: { display: 'flex', alignItems: 'center'}, cellRenderer: ({ data }) => (
      <input type="image" src={recover_png} onClick={e => recoverModal(data)} width={18} />)},
      );

  }


  if (gridApi) {
    gridApi.api.setServerSideDatasource(datasource);
     }

  }, [isActive]);



  const defaultColDef = useMemo(() => ({
    flex:1,
    filter: false,
    sortable: true,
    resizable: true,
    wrapText: true,
    autoHeight: true,
  }), []);


  const onGridReady = (params) => {
    setGridApi(params);
    params.api.setServerSideDatasource(datasource);
  }

  const refreshTable = () => {
  gridApi.api.refreshServerSide();

  }

  var datasource = useMemo(() => {
    return {
      getRows(params) {
      const { startRow, endRow, filterModel, sortModel } = params.request;
         
      
      let data = {
          startRow: startRow,
          endRow: endRow,
          isActive: isActive,
        };

        //Sorting
        if (sortModel.length) {
        const { colId, sort } = sortModel[0]
        data.sortField = colId;
        data.sortDirection = sort;
      }

            
        api.get(`/Product/getall`, {params: data}).then(response => {
        params.successCallback(response.data.data, response.data.totalRecords);
        if(!response.data.status){
        toast.error('Failed to get Data')
        }
      })
    }
  }
}, [isActive]);
  



    useEffect(() => { 

    if (search.length > 0) {
    setSearchState(true);

    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {

    var datasource = {
    getRows(params) {

    let data = {
    isActive: isActive,
    search: search,
    };

    api.get(`/Product/getall`, {params: data}).then(response => {
    params.successCallback(response.data.data, response.data.totalRecords);
    if(!response.data.status){
    toast.error('Failed to get Data')
    }})}}
    gridApi.api.setServerSideDatasource(datasource);
    }, 1000);
    }else{
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    if (searchState) onGridReady(gridApi);
    }
    }, [search]);



  
  return (
    <div>     
    
    <ProductAdd showModal={showAddModal} toggleModal={addModal} refreshTable={refreshTable}  />
    <ProductEdit showModal={showEditModal} toggleModal={editModal} refreshTable={refreshTable}   idRow={idRow} />
    <ProductTrash showModal={showTrashModal} toggleModal={trashModal} refreshTable={refreshTable}  idRow={idRow}   />
    <ProductRecover showModal={showRecoverModal} toggleModal={recoverModal} refreshTable={refreshTable}  idRow={idRow}   />
    
      
      <div className="ag-theme-alpine" style={{ width: '100%', height: '73vh' }} >
        <AgGridReact
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        animateRows={true}
        rowModelType={'serverSide'}
        onGridReady={onGridReady}
        serverSideInfiniteScroll={true}
        pagination={true}
        paginationPageSize={10}
        cacheBlockSize={10}
        />
      </div>

      
    </div>
  );
}

export default ProductTable;
