import React, { useCallback, useEffect, useMemo, useState, memo, useRef } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import moment from 'moment';

import { api } from "../../dynamic/api";
import toast from 'react-hot-toast';


function StockActivityTable(props) {

  const { isActive, search, fromDate, toDate} = props;


  const [columnDefs, setColumnDefs] = useState([]);
  const [gridApi, setGridApi] = useState(null);

  const [searchState, setSearchState] = useState(false);


  const timeoutRef = useRef(null);

//-----------------------------------------------------------------

function dateFormatter(e) {
  const date = new Date(e.value);
  const formattedDate = moment.utc(date).format('DD-MM-YY hh:mm A');
  return formattedDate;
}

function typeComlumn(e) {
   
  if(e.data.type == 'in'){
   return <><span className='type-green'>in</span></>
  }else if(e.data.type == 'out'){
   return <><span className='type-red'> out</span></>
  }else if(e.data.type == 'transfer'){
    return <><span className='type-yellow'> transfer</span></>
   }

}


  useEffect(() => {

  let newColumnDefs = [
    { field: 'id' , hide: true},
    { field: 'createdAt', valueFormatter: dateFormatter},
    { field: 'product.name', headerName: 'Product'},
    { field: 'quantity'},
    { field: 'type', headerName: 'Type', cellRenderer: typeComlumn},
    { field: 'details'},
    { field: 'created.name', headerName: 'User'},
  ];

  setColumnDefs(newColumnDefs);


  if (gridApi) {
    gridApi.api.setServerSideDatasource(datasource);
     }

  }, [isActive, fromDate, toDate]);



  const defaultColDef = useMemo(() => ({
    flex:1,
    filter: false,
    sortable: true,
    resizable: true,
    wrapText: true,
    autoHeight: true,
  }), []);


  const onGridReady = (params) => {
    setGridApi(params);
    params.api.setServerSideDatasource(datasource);
  }

  const refreshTable = () => {
  gridApi.api.refreshServerSide();

  }

  var datasource = useMemo(() => {
    return {
      getRows(params) {
      const { startRow, endRow, filterModel, sortModel } = params.request;
         
      
      let data = {
          startRow: startRow,
          endRow: endRow,
          isActive: isActive,
          fromDate: fromDate,
          toDate: toDate,
        };

        //Sorting
        if (sortModel.length) {
        const { colId, sort } = sortModel[0]
        data.sortField = colId;
        data.sortDirection = sort;
      }

            
        api.get(`/stockActivity/getall`, {params: data}).then(response => {
        params.successCallback(response.data.data, response.data.totalRecords);
        if(!response.data.status){
        toast.error('Failed to get Data')
        }
      })
    }
  }
}, [isActive, fromDate, toDate]);
  



    useEffect(() => { 

    if (search.length > 0) {
    setSearchState(true);

    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {

    var datasource = {
    getRows(params) {

    let data = {
    isActive: isActive,
    search: search,
    };

    api.get(`/stockActivity/getall`, {params: data}).then(response => {
    params.successCallback(response.data.data, response.data.totalRecords);
    if(!response.data.status){
    toast.error('Failed to get Data')
    }})}}
    gridApi.api.setServerSideDatasource(datasource);
    }, 1000);
    }else{
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    if (searchState) onGridReady(gridApi);
    }
    }, [search]);



  
  return (
    <div>     

    
      
      <div className="ag-theme-alpine" style={{ width: '100%', height: '73vh' }} >
        <AgGridReact
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        animateRows={true}
        rowModelType={'serverSide'}
        onGridReady={onGridReady}
        serverSideInfiniteScroll={true}
        pagination={true}
        paginationPageSize={10}
        cacheBlockSize={10}
        />
      </div>

      
    </div>
  );
}

export default StockActivityTable;
