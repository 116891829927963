import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

// Update the token stored in cookies and return it
const updateToken = () => {
  return Cookies.get('token') || null;
};

// Retrieve the user information from the token, if available
const token = updateToken();
const decodedToken = token ? jwtDecode(token) : null;
const userId = decodedToken?.user?.id || '';
const userName = decodedToken?.user?.name || '';
const userRole = decodedToken?.user?.role || '';
const userBranchId = decodedToken?.user?.branch || '';
const userPermission = decodedToken?.user?.permission || '';

export {
  token,
  userId,
  userName,
  userRole,
  userBranchId,
  userPermission,
};
