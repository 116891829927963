import React, { useState, useEffect, useContext } from 'react';
import toast, {Toaster} from 'react-hot-toast';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import { api } from "../dynamic/api";

import { DataContext } from '../getData/getData';

function Settings() {

  const { settingsData, refreshSettings } = useContext(DataContext);

 
  const [rate, setRate] = useState([]);
  const [skyNetDel, setSkyNetDel] = useState([]);
  const [beirutDel, setBeirutDel] = useState([]);

  useEffect(() => {

      setRate(settingsData.rate);
      setSkyNetDel(settingsData.skynet);
      setBeirutDel(settingsData.beirut);

  }, [])


  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = document.getElementById("settingForm");
    const formData = new FormData(form);

    let data= {
      rate: rate,
      skyNetDel: skyNetDel,
      beirutDel: beirutDel,

    }
      
    let res = await api.post(`/setting/editAll`, JSON.stringify(data))
    if(res.data.status){
      toast.success(res.data.message);
      refreshSettings();
    }
    else{
      toast.error(res.data.message);
    }
 
  }



  return (
    <div className="card">
      <span className='tittle' >Settings</span>
      <Form onSubmit={handleSubmit} style={{width : '50%', margin: '0 auto', marginTop:'30px' }} id='settingForm'>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={2}>Rate</Form.Label>
        <Col sm={10}>
          <Form.Control value= {rate} onChange={(e) => setRate(e.target.value)} type="number"  step='any' placeholder="Rate" style={{width : '70%'}} />
        </Col>
      </Form.Group>


      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={2}>SkyNet</Form.Label>
        <Col sm={10}>
          <Form.Control value= {skyNetDel} onChange={(e) => setSkyNetDel(e.target.value)} type="number"  step='any' placeholder="Delivery" style={{width : '70%'}} />
        </Col>
      </Form.Group>


      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={2}>Beirut</Form.Label>
        <Col sm={10}>
          <Form.Control value= {beirutDel} onChange={(e) => setBeirutDel(e.target.value)} type="number"  step='any' placeholder="Delivery" style={{width : '70%'}} />
        </Col>
      </Form.Group>




      <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
        <Form.Label column sm={2}>
          language
        </Form.Label>
        <Col sm={10}>
          <select className="form-control" style={{width : '70%'}}>
            <option>English</option>
          </select>
        </Col>
      </Form.Group>


      <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
        <Form.Label column sm={2}>
          Currency
        </Form.Label>
        <Col sm={10}>
          <select className="form-control" style={{width : '70%'}}>
            <option>USD</option>
          </select>
        </Col>
      </Form.Group>

       {/* 
      <fieldset>
        <Form.Group as={Row} className="mb-3">
          <Form.Label as="legend" column sm={2}>
            Radios
          </Form.Label>
          <Col sm={10}>
            <Form.Check
              type="radio"
              label="first radio"
              name="formHorizontalRadios"
              id="formHorizontalRadios1"
            />
            <Form.Check
              type="radio"
              label="second radio"
              name="formHorizontalRadios"
              id="formHorizontalRadios2"
            />
            <Form.Check
              type="radio"
              label="third radio"
              name="formHorizontalRadios"
              id="formHorizontalRadios3"
            />
          </Col>
        </Form.Group>
      </fieldset>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalCheck">
        <Col sm={{ span: 10, offset: 2 }}>
          <Form.Check label="Remember me" />
        </Col>
      </Form.Group>


      */}
      <Form.Group as={Row} className="mb-3">
        <Col sm={{ span: 10, offset: 2 }}>
          <Button type="submit" className="btn btn-primary">Save</Button>
        </Col>
      </Form.Group>

       
    </Form>
    </div>
  );
}

export default Settings;
