import React, { useState, useEffect, useContext  } from 'react';

import StockTable from './stockTable';


function StockHeader(props) {
  
 
  const [isActive, setIsActive] = useState('true');
  const [search, setSearch] = useState('');

  const [stockCost, setStockCost] = useState(0);

 
const isActiveChange = (e) => {
    setIsActive(e);
};


  return (
    <div className='card' style={{userSelect: 'none'}}>

    
        <div>
        
        <input type="search" className="form-control search" placeholder="Search" onChange={(e) => setSearch(e.target.value)}  ></input>


        
        <select name="groupId" className="form-control dropDown" id="groupId" onChange={(e) => isActiveChange(e.target.value)} >
          <option value='true' >Active</option>
          <option value='false'>Deleted</option>
        </select>
   
        <span className="header-total">
            {stockCost} $
          </span>

    

      </div>
  
      <StockTable isActive={isActive} setStockCost={setStockCost} search={search} />
      

    </div>
  )
}

export default StockHeader;
