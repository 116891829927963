import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import toast, {Toaster} from 'react-hot-toast';

import { userId } from "../../dynamic/token";

const Discount = (props) => {

  const { showModal, toggleModal, setDiscount} = props;
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setDiscount(parseInt(document.getElementById('discount').value));
    toggleModal();
  }


  return (
      <Modal show={showModal} onHide={toggleModal} >
      <Modal.Header style={{ background: "#1f2937" }}>
      <Modal.Title style={{ color: "white" }}>Add Discount</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <form onSubmit={handleSubmit} id='addForm'>

      {userId === 4 && (
      <div className="mb-3 row">
      <label className="col-sm-2 col-form-label">&nbsp; &nbsp; &nbsp; &nbsp;%</label>
      <div className="col-sm-10">
      <input type="number" defaultValue='0' name="discount" id="discount" className="form-control"></input>
      </div>
      </div>
      )}


      {userId !== 4 && (
      <div className="mb-3 row">
      <label className="col-sm-2 col-form-label">&nbsp; &nbsp; &nbsp; &nbsp;%</label>
      <div className="col-sm-10">
      <input type="number" min="0" max="20" defaultValue='0' name="discount" id="discount" className="form-control"></input>
      </div>
      </div>
      )}



      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={toggleModal}>
          Close
        </button>
        <input type="submit" className="btn btn-primary" value="Add Discount"></input>
      </div>
      </form>
      </Modal.Body>
      </Modal>
      )
}


export default Discount;