import React, { useCallback, useEffect, useMemo, useState, memo, useRef } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import '@ag-grid-enterprise/excel-export';

import moment from 'moment';

import CategoryAdd from './crud/categoryAdd';
import CategoryEdit from './crud/categoryEdit';
import CategoryTrash from './crud/categoryTrash';
import CategoryRecover from './crud/categoryRecover';


import { userId } from "../../dynamic/token";
import { userRole } from "../../dynamic/token";
import { api } from "../../dynamic/api";

import toast from 'react-hot-toast';

import edit_png from '../../images/edit.png';
import delete_png from '../../images/trash.png';
import recover_png from '../../images/rotate.png';

const dataFormat = require("../../dynamic/date");


function CategoryTable(props) {

  const { isActive, showAddModal, addModal } = props;

  const [showEditModal, setShowEditModal] = useState(false);
  const [showTrashModal, setShowTrashModal] = useState(false);
  const [showRecoverModal, setShowRecoverModal] = useState(false);

  const [idRow, setIdRow] = useState([]);

  const [columnDefs, setColumnDefs] = useState([]);
  const [gridApi, setGridApi] = useState(null);


//-----------------------------------------------------------------



  const editModal = (e) => {
    if (e != undefined) {
      setIdRow(e);
    }
    setShowEditModal(!showEditModal);
  }

  const trashModal = (e) => {
    if (e != undefined) {
      setIdRow(e);}
      setShowTrashModal(!showTrashModal);
  }

  const recoverModal = (e) => {
    if (e != undefined) {
      setIdRow(e);}
      setShowRecoverModal(!showRecoverModal);
  }

 
  function dateFormatter(e) {
    const date = new Date(e.value);
    const formattedDate = moment.utc(date).format('DD-MM-YY hh:mm A');
    return formattedDate;
  }

 
  useEffect(() => {



  let newColumnDefs = [
    { field: 'id'},
    { field: 'name'},
    { field: 'details'},
  ];



  setColumnDefs(newColumnDefs); 

  if(isActive == 'true'){
    
  newColumnDefs.push(
  
    {field: 'edit', headerName: 'Edt', flex: 0.5, sortable: false, cellRenderer: ({ data }) => (
      <input type="image" src={edit_png} onClick={e => editModal(data)} width={17} />)},
    
    {field: 'delete', headerName: 'Del', flex: 0.5, sortable: false, cellRenderer: ({ data }) => (
    <input type="image" src={delete_png} onClick={e => trashModal(data)} width={18} />)},
    );
  }else{
    newColumnDefs.push(      
      {field: 'recover', headerName: 'Rec', flex: 0.5, sortable: false, cellRenderer: ({ data }) => (
      <input type="image" src={recover_png} onClick={e => recoverModal(data)} width={18} />)},
      );

  }

  if (gridApi) {
    gridApi.api.setServerSideDatasource(datasource);
     }

  }, [isActive]);


  const defaultColDef = useMemo(() => ({
    filter: false,
    sortable: true,
    resizable: true,
    wrapText: true,
    autoHeight: true,
  }), []);


  const onGridReady = (params) => {
    setGridApi(params);
    params.api.setServerSideDatasource(datasource);
  }

  const refreshTable = () => {
  gridApi.api.refreshServerSide();

  }

  var datasource = useMemo(() => {
    return {
      getRows(params) {
      const { startRow, endRow, filterModel, sortModel } = params.request;
         
      
      let data = {
          startRow: startRow,
          endRow: endRow,
          isActive: isActive,
        };

        //Sorting
        if (sortModel.length) {
        const { colId, sort } = sortModel[0]
        data.sortField = colId;
        data.sortDirection = sort;
      }

            
        api.get(`/category/getAll`, {params: data}).then(response => {
        params.successCallback(response.data.data, response.data.totalRecords);
        if(!response.data.status){
        toast.error('Failed to get Data')
        }
      })
    }
  }
}, [isActive]);
  
  
  return (
    <div>     
    
    <CategoryAdd showModal={showAddModal}  toggleModal={addModal}  refreshTable={refreshTable} tittle={'Category'} />
    <CategoryEdit showModal={showEditModal} toggleModal={editModal} refreshTable={refreshTable} tittle={'Category'}  idRow={idRow}  />
    <CategoryTrash showModal={showTrashModal} toggleModal={trashModal} refreshTable={refreshTable} tittle={'Category'} idRow={idRow}  />
    <CategoryRecover showModal={showRecoverModal} toggleModal={recoverModal} refreshTable={refreshTable} tittle={'Category'} idRow={idRow}  />
   
      
      <div className="ag-theme-alpine" style={{ width: '100%', height: '75vh' }} >
        <AgGridReact
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        animateRows={true}
        rowModelType={'serverSide'}
        onGridReady={onGridReady}
        serverSideInfiniteScroll={true}
        pagination={true}
        paginationPageSize={200}
        cacheBlockSize={200}
        rowSelection={'multiple'}
        />
      </div>

      
    </div>
  );
}

export default CategoryTable;
