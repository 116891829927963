import moment from 'moment';
const dataFormat = require("../dynamic/date");

const printPaper = (allRows) => {

  if(document != null){
  
  const dateNow = new Date(dataFormat.getDateFormatted());
  const formattedDate = moment.utc(dateNow).format('DD-MM-YY hh:mm A');

  if(allRows.length > 0){
    const form = document.createElement("formid");
    form.innerHTML = `
    <style>
      @media print {
        .page-break {
          page-break-before: always;
        }
      }
    </style>
    
    ${allRows.map((row) => `

    <div class="page-break">

    <p style="text-align:center;"><label>Mark Store</label></p>
    <p style="text-align:center;"><label>03 601766</label></p>
    <p style="text-align:center;"><label>Date: ${formattedDate}</label></p>
    <label >------------------------------</label>
    <p><label>OrderId: ${row.id}</label></p>
    <p><label>Customer: ${row.firstName}</label></p>
    <p><label>Mobile: ${row.mobile}</label></p>
    <label >------------------------------</label>
    <p><label>Location: ${row.landmark}</label></p>
    <p><label>Order: ${row.specialInstructions}</label></p>
    <label >------------------------------</label>
    <p><label>Total &nbsp; &nbsp; ${row.usd}$</label></p>
    <p><label>Total &nbsp; &nbsp; ${row.lbp}lbp</label></p>
    </div>
         
        `
      )
      .join("")}`;

   
  
      const newWindow = window.open();
      newWindow.document.body.appendChild(form);
      newWindow.print();
      newWindow.close();
    }
    
    
};
}





export {
  printPaper
};