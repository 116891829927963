import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import toast, {Toaster} from 'react-hot-toast';

import { api } from "../../dynamic/api";

import Select from 'react-select';

const AdminOrderEdit = (props) => {

  const {showModal, toggleModal, idRow, refreshTable, cityData, isBeirut} = props;

  const [selectedCity, setSelectedCity] = useState();
  const [cityDropDown, setCityDropDown] = useState('true');


  useEffect(() => {
    if(isBeirut == 'true'){
      setCityDropDown('false')
    }
    else{
      setCityDropDown('true')
    }

  }, [isBeirut]);

  
  useEffect(() => {

    let dataArray = transformObject(idRow);

    console.log(dataArray)

   setSelectedCity({value: dataArray.cityId+'' , label: dataArray.cityName})

    for (const prop in dataArray) {
      const field = document.getElementById(prop);
  
      if (field) {
        field.value = dataArray[prop];
      }
    } 

  }, [idRow]);

  function transformObject(obj) {
    for (let key in obj) {
      if (typeof obj[key] === 'object') {
        const nestedObj = obj[key];
        for (let nestedKey in nestedObj) {
          const newKey = `${key}${nestedKey.charAt(0).toUpperCase()}${nestedKey.slice(1)}`;
          obj[newKey] = nestedObj[nestedKey];
        }
        delete obj[key];
      }
    }
    return obj;
  }
  

  const handleSubmit = async (e) => {
    
    e.preventDefault();
    const form = document.getElementById("editGroup");
    
    const formData = new FormData(form);
    const data = Object.fromEntries(formData);
    if (data.mobile.length > 7) {
    data['id'] = idRow.id; 
    
    let res = await api.post(`/order/edit`, JSON.stringify(data))
    if(res.data.status){
      toast.success(res.data.message);
    }else{
      toast.error(res.data.message);
    }
    toggleModal();
    form.reset();
    refreshTable();
  }
  else {
    toast.error('Please Check mobile number');
  }
  }


  const options = cityData.map(item => ({
    value: item.id,
    label: item.name
  }));


  const changeDelivery = (value) => {
    setCityDropDown(value === 'true' ? 'false' : 'true');
  }
  


 
    return (
      <Modal show={showModal} onHide={toggleModal}>
        <Modal.Header style={{ background: "#1f2937" }}>
          <Modal.Title style={{ color: "white" }}>Edit Order</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <form onSubmit={handleSubmit} id='editGroup'>
      <div className="modal-body">

      <div className="mb-3 row">
      <label className="col-sm-2 col-form-label">Name</label>
      <div className="col-sm-10">
      <input type="text" name="firstName" id="firstName" className="form-control"></input>
      </div>
      </div>

      <div className="mb-3 row">
      <label className="col-sm-2 col-form-label">Phone</label>
      <div className="col-sm-10">
      <input type="text" name="mobile" id="mobile" className="form-control"></input>
      </div>
      </div>


      
      <div className="mb-3 row">
      <label className="col-sm-2 col-form-label">Delivery</label>
      <div className="col-sm-10">
      <select name='isBeirut' className="form-control" id= 'isBeirut' onChange={(event) => changeDelivery(event.target.value)}>
      <option value='false'>Sky Net</option>
      <option value='true'>Beirut</option>
      </select>
      </div>
      </div>

      {cityDropDown == 'true' && (
      <div className="mb-3 row">
      <label  className="col-sm-2 col-form-label">City</label>
      <div className="col-sm-10">
      <Select
      style= {{ 'width': '100px'}}
      options={options}
      isSearchable={true}
      name="cityId"
       id="cityId"
       value={selectedCity}
       onChange={(selected) => setSelectedCity(selected)}
      />
      </div>
      </div>
      )}

      <div className="mb-3 row">
      <label  className="col-sm-2 col-form-label">Location</label>
      <div className="col-sm-10">
      <textarea className="form-control" name="landmark" id="landmark"></textarea>
      </div></div>

      <div className="mb-3 row">
      <label  className="col-sm-2 col-form-label">Order</label>
      <div className="col-sm-10">
      <textarea className="form-control" name="specialInstructions" id="specialInstructions"></textarea>
      </div></div>

      <div className="mb-3 row">
      <label className="col-sm-2 col-form-label">USD</label>
      <div className="col-sm-10">
      <input type="number" step="any" name="usd" id="usd" className="form-control"></input>
      </div>
      </div>


      <div className="mb-3 row">
      <label className="col-sm-2 col-form-label">LBP</label>
      <div className="col-sm-10">
      <input type="number" step="any" name="lbp" id="lbp" className="form-control"></input>
      </div>
      </div>

      </div>
      
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={toggleModal}>
          Close
        </button>
        <input type="submit" className="btn btn-primary" value="Save Data"></input>
      </div>
      </form>
        </Modal.Body>
      </Modal>
    );
  };
  
  export default AdminOrderEdit;
