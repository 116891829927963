import './App.css';
import React, { useState, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { useNavigate } from "react-router-dom";

import Menu from './menu/menu';
import GetData from './getData/getData';
import LogIn from './login/login';
import { token } from "./dynamic/token";

import toast, {Toaster} from 'react-hot-toast';

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  const [isTokenValid, setIsTokenValid] = useState(false);
  const [showToast, setShowToast] = useState(false);

  // Check if the token is valid when the component mounts
  useEffect(() => {
    if (token != null) {
      const decodedToken = jwtDecode(token);
      if (decodedToken.exp * 1000 < Date.now()) {
        toast.error('session expired, Login again');
      } else {
        setIsTokenValid(true);
        toast.success('Loged in Successfully');
      }
    } else {
      toast.error('You need to Login');
      navigate('/login', { replace: true });
    }
  }, []);

;

  return (
    <div className="app">
      <Toaster position="top-right" reverseOrder={false}/>
      <Routes>
        <Route path="/*" element={isTokenValid && <GetData />} />
        <Route path='/login' element={<LogIn />} />
      </Routes>
    </div>
  );
}

export default App;
