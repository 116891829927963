import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';

import toast, {Toaster} from 'react-hot-toast';
const {api} = require("../dynamic/api");

function LogIn (){

    const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = document.getElementById("check_login_form");
      
      const formData = new FormData(form);
      
      //formData.append("type", this.props.cashType)
      const data = Object.fromEntries(formData);
      let res = await api.post("/user/login", JSON.stringify(data))
      if(!res.data.status){  
        toast.error(res.data.message);
      }
      else{
        Cookies.set('token', res.data.token, { expires: 7 });
        window.location.assign('/');
      }
      

  }
  
      return (
       
        <div className="container">

         

        <div>
       
       </div>
       

        


            
            
      <div className="row justify-content-center form-bg-image" >
      
            <div className="col-12 d-flex align-items-center justify-content-center">
            <div className="bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500">
            <div className="text-center text-md-center mb-4 mt-md-0">
            <h1 className="mb-0 h3">Sign in to our platform</h1>
            </div>
            <form method="POST" id="check_login_form" onSubmit={handleSubmit}>
            <div className="form-group mb-4">
            <label> User Name</label>
            <div className="input-group">
            <span className="input-group-text" id="basic-addon1">
            <svg className="icon icon-xs text-gray-600" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path><path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path></svg>
            </span>
            <input type="email" className="form-control" placeholder="example@company.com" id="email" name="email" required></input>
            </div>  
            </div>
            <div className="form-group">
            <div className="form-group mb-4">
            <label>Your Password</label>
            <div className="input-group">
            <span className="input-group-text" id="basic-addon2">
            <svg className="icon icon-xs text-gray-600"  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd"></path></svg>
            </span>
            <input type="password" placeholder="Password" className="form-control" id="password" name="password" required></input>
            </div>  
            </div>
            </div>
            <div className="d-flex justify-content-between align-items-top mb-4">
            </div>
            <div className="d-grid">
            <input type="submit" className="btn btn-primary" value="Sign in"></input>
            </div>
            </form>
            </div>
            </div>
            </div>
           </div>
    );
  
}

export default LogIn;