import React, { useState, useEffect, useRef, useContext } from 'react';
import toast, {Toaster} from 'react-hot-toast';

import { Modal } from 'react-bootstrap';

import Discount from './crud/discount';
import Details from './crud/details';
import Quantity from './crud/quantity';

import delete_png from '../images/trash.png';
import './sales.css';
//images
import productImage from '../images/box.png';
import discountImage from '../images/tag.png';

import { api } from "../dynamic/api";
import { DataContext } from '../getData/getData';

import Spinner from 'react-bootstrap/Spinner';


const Sales = (props) => {

  const { showModal, toggleModal, refreshTable, idRow} = props;

  const {categoryData, productData, settingsData} = useContext(DataContext);

  const [cart, setCart] = useState([]);

  const [showproducts, setShowProducts] = useState([]);
  const [total, setTotal] = useState(0);
  const [productDiscountId, setProductDiscountId] = useState();

  const [showDiscountModal, setDiscountModal] = useState(false);
  const [showQuantityModal, setQuantityModal] = useState(false);
  const [showDetailsModal, setDetailsModal] = useState(false);

  const [loadingEffect, setLoadingEffect] = useState(false);

  const [index, setIndex] = useState(false);

  const searchRef = useRef(null);

  const [cartData, setCartData] = useState([]);


  
  const getImagesByIds = async (showProductIds) => {
    try {
      let data = { showProductIds: showProductIds };
  
      const response = await api.get(`/product/getImages`, { params: data });
  
      // Update the setShowProducts state by merging the image property from the response
      setShowProducts((prevShowProducts) =>
        prevShowProducts.map((product) => {
          const matchingProduct = response.data.data.find((item) => item.id === product.id);
          if (matchingProduct) {
            return {
              ...product,
              imageFile: matchingProduct.imageFile,
            };
          }
          return product;
        })
      );
    } catch (error) {
      toast.error('' + error);
    }
  };

  const discountModal = () => {
    setDiscountModal(!showDiscountModal);
  }

  const quantityModal = (index) => {
    setIndex(index);
    setQuantityModal(!showQuantityModal);
  }

  const detailsModal = (index) => {
    setIndex(index);
    setDetailsModal(!showDetailsModal);
  }

  const changeDetails = (e, index) => {
    const updatedCart = [...cart];
    updatedCart[index] = { ...updatedCart[index], details: e };
    setCart(updatedCart) 
}

const changeQuantity = (e, index) => {
  const updatedCart = [...cart];
  updatedCart[index] = { ...updatedCart[index], quantity: e };
  setCart(updatedCart) 
}

  useEffect(() => {
    if(idRow.id && showModal == true){
      getCartData();
    }
  }, [idRow, showModal]);

  const getCartData = async() => {
    let data = {isActive: true, orderId: idRow.id};
    await api.get(`/cart/getAll`, { params: data }).then((results) => {
    if(results){
      setCartData(results.data.data);
    }}).catch((error) => {
    toast.error(''+error); }); 
    }


    useEffect(() => {
      
      if (cartData) {
        cartData.forEach((product) => {
          setCart((prevCart) => [
            ...prevCart,
            { ...product, quantity: parseInt(product.quantity), discount: 0, price: product.price/product.quantity }
          ]);
          setTotal((prevTotal) => prevTotal + parseFloat(product.price));
        });
      }
    }, [cartData]);

  
    


const handleAddToCart = async (product) => {
      const productWithoutImage = { ...product };
      delete productWithoutImage.image;
      delete productWithoutImage.imageFile;
      setCart([...cart, { ...productWithoutImage, quantity: 1, discount: 0 }]);
      setTotal(parseFloat(total) + parseFloat(product.price));
  };


  const closeModal = () => {
    setCart([]);
    setTotal(0);
    toggleModal();
  }




const handleRemoveFromCart = (index) => {
      const updatedCart = [...cart];
        updatedCart.splice(index, 1);
        setCart(updatedCart);
      updateCartTotal(updatedCart);
  };




  const handleDiscount = (product) => {
   
    setProductDiscountId(product.id);
    discountModal();
  }



  const setDiscount =  (value) => {
    const index = cart.findIndex((item) => item.id === productDiscountId);
   
    if (index >= 0) {
      const updatedCart = [...cart];
      updatedCart[index].discount = value;
      setCart(updatedCart);
      updateCartTotal(updatedCart);
    
    }
  }



  
  function updateCartTotal(items) {
    let total = 0;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const itemDiscount = (item.price * item.quantity) * item.discount / 100;
      const itemTotal = item.price * item.quantity - itemDiscount;
      total += itemTotal;
    }
    setTotal(total);
  }

  

  





// Place Order
  const placeOrder = async() => {
    setLoadingEffect(true);

    if(cart.length > 0){  
      let usd = total;

    let data = {
      orderId : idRow.id,
      cart: cart,
      usd: usd,
      settingsData: settingsData,
      isBeirut: idRow.isBeirut,
    };

    let res = await api.post(`/order/place`, JSON.stringify(data));
    if(res.data.status){
      toast.success(res.data.message);
      refreshTable();
      closeModal();
    }else{
      toast.error('Failed, Contact Support');
    }
    setLoadingEffect(false);
   
  }
  else {
    toast.error('Cart is empty');
    setLoadingEffect(false);
  }
 
  }

  const selectCategory = (catId) => {
    const filteredProducts = productData.filter(item => item.category.id === catId);
    setShowProducts(filteredProducts);
    const showProductIds = filteredProducts.map((item) => item.id);
    getImagesByIds(showProductIds)
  }

  
  
  

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      let filteredProducts =[];
      let searchValue = searchRef.current.value;
      
        
        filteredProducts = productData.filter(product => {
          return product.name.includes(searchValue) || product.barcode === searchValue;
        });
      setShowProducts(filteredProducts);
      searchRef.current.value = '';
    }
    
  };


 


  return (

    <Modal size="xl" show={showModal} onHide={toggleModal} fullscreen={true}>
    <Modal.Header style={{ background: "#1f2937" }}>
    <Modal.Title style={{ color: "white" }}>Add Products</Modal.Title>
    <span style={{ float: 'left', color: 'white', cursor: 'pointer'}} onClick={closeModal} >X</span>
    </Modal.Header>
    <Modal.Body>
    
    <div className="card" style={{ height: '75vh', userSelect: 'none' }}>

   <Discount showModal={showDiscountModal} toggleModal={discountModal} setDiscount={setDiscount} />
   <Details  showModal={showDetailsModal} toggleModal={detailsModal} index={index} changeDetails={changeDetails} />
   <Quantity  showModal={showQuantityModal} toggleModal={quantityModal} index={index} changeQuantity={changeQuantity} />
 

  <div style={{ height: '50px' }}>
    <input type="search" ref={searchRef} onKeyDown={handleKeyDown} className="form-control searchItem" placeholder="Search" />


      </div>
    

  <div style={{ display: 'flex', height: 'calc(75vh - 50px)' }}>
  
  <div className="card" style={{ display: 'flex', height: 'calc(75vh - 50px)', width: '16%', overflowY: 'auto', maxHeight: 'calc(90vh - 50px)' }}>
  {categoryData.map((category) => (
    <div key={category.id}>
      <span className="subCat" onClick={() => selectCategory(category.id)}>
        {category.name}
      </span>
      <div>
      </div>
    </div>
  ))}
</div>





<div className="card" style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))', gap: '0px', overflowX: 'auto', overflowY: 'auto', width: '70%', height: 'calc(75vh - 50px)' }}>

      {showproducts.map((product) => (
        <div className='card' key={product.id} style={{ margin: '0px', justifyContent: 'center', textAlign: 'center', width: '150px', height:'200px', cursor: 'pointer' }} onClick={() => handleAddToCart(product)}>
        <img src={`data:image/png;base64,${product.imageFile}`} alt="Product Image" width={120} style={{ width: '120px', margin: '0 auto' }} />
          <div style={{ fontSize: '15px', fontWeight:'bold', textAlign: 'center' }}>{product.name}</div>
          <div style={{ fontSize: '15px', textAlign: 'center', userSelect: 'none', color: 'green', fontFamily : 'sans-serif' }}>{product.price.toLocaleString()} $</div>
        </div>
      ))}
  
    </div>




    <div className="card" style={{ height: 'calc(75vh - 50px)', width: '30%', padding: 10, display: 'flex', flexDirection: 'column' }}>
      <div style={{ overflowY: 'auto', flex: 1 }}>
        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <th style={{ width: '40%', paddingBottom: '15px', cursor: 'default' }}>Item</th>
              <th style={{ width: '10%', paddingBottom: '15px', cursor: 'default' }}>Desc</th>
              <th style={{ width: '10%', paddingBottom: '15px', cursor: 'default' }}>Qnty</th>
              <th style={{ width: '20%', textAlign: 'center', paddingBottom: '15px', cursor: 'default' }}>Price</th>
              <th style={{ width: '20%', textAlign: 'center', paddingBottom: '15px', cursor: 'default' }}>Del</th>
            </tr>
          </thead>
          <tbody>
            
          {cart.map((product, index) => (
              <tr key={index}>

              <td style={{paddingBottom: '20px'}}>{product.name}</td>

              <td style={{ cursor: 'pointer', paddingBottom: '20px' }} onClick={() => detailsModal(index)}>
              {product.details !== '' && product.details !== null ? product.details : '+'}
              </td>

              <td style={{ cursor: 'pointer', paddingBottom: '20px' }} onClick={() => quantityModal(index)}>
                x{product.quantity}
              </td>
                
  

                <td style={{ textAlign: 'center', paddingBottom: '20px' }}>
                {((product.price * product.quantity) - (product.price * product.quantity * (product.discount/100))).toFixed(2)}
                  </td>
                <td style={{ textAlign: 'center', paddingBottom: '20px' }}>
                  <input type="image" src={delete_png} onClick={() => handleRemoveFromCart(index)} width={17} />
                </td>
              </tr>
            ))}
            
          </tbody>
          </table>
        </div>
        <div style={{ marginTop: 10, fontSize: '100%',cursor: 'default'  }}><a style={{ fontWeight: 'bold'}}>Total Price:</a> {total.toFixed(2)}$</div>
        
        <div className="card" style={{ marginTop: 10, padding: 10,alignSelf: 'flex-end',}}>
      
        
        {loadingEffect && (
        <div style={{textAlign: 'center'}}><Spinner animation="border" variant="success" /></div>
        )}

        <span className='place' onClick={placeOrder} >Place Order</span>
        </div>
        
      </div>
    </div>
    </div>

    </Modal.Body>
      </Modal>
  );
};

export default Sales;
