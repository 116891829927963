import axios from 'axios';
import Cookies from 'js-cookie';

const updateApi = () => {
  let url="https://admin.adamjemle.com:5001/api";
  let token = Cookies.get('token');
  
  return axios.create({
      baseURL: url,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
};

let api = updateApi();

export  { api, updateApi};