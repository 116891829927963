import React, { useState, useRef, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import toast, {Toaster} from 'react-hot-toast';

import { api } from "../../dynamic/api";
import { userId } from "../../dynamic/token";

import Select from 'react-select';



const AdminOrderAdd = (props) => {

  const { showModal, toggleModal, refreshTable, cityData, isBeirut } = props;


  const handleSubmit = async (e) => {

    e.preventDefault();
    const form = document.getElementById("addForm");
      
    const formData = new FormData(form);
    const data = Object.fromEntries(formData);
    
    if (data.mobile.length > 7) {
    data['userId'] = userId;  
    data['isBeirut'] = isBeirut;  

    let res = await api.post(`/order/add`, JSON.stringify(data))
    if(res.data.status){
      toast.success(res.data.message);
    }else{
      toast.error(res.data.message);
    }
    toggleModal();
    form.reset();
    refreshTable();
  }
  else {
    toast.error('Please Check mobile number');
  }
  }

  const options = cityData.map(item => ({
    value: item.id,
    label: item.name
  }));




  return (
      <Modal show={showModal} onHide={toggleModal} >
      <Modal.Header style={{ background: "#1f2937" }}>
      <Modal.Title style={{ color: "white" }}>Add Order</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <form onSubmit={handleSubmit} id='addForm'>
      <div className="modal-body">

      <div className="mb-3 row">
      <label className="col-sm-2 col-form-label">Name</label>
      <div className="col-sm-10">
      <input type="text" name="firstName" id="firstName" className="form-control"></input>
      </div>
      </div>

      <div className="mb-3 row">
      <label className="col-sm-2 col-form-label">Phone</label>
      <div className="col-sm-10">
      <input type="text" name="mobile" id="mobile" className="form-control"></input>
      </div>
      </div>

      {isBeirut == 'false'  && (
      <div className="mb-3 row">
      <label  className="col-sm-2 col-form-label">City</label>
      <div className="col-sm-10">
      <Select
      style= {{ 'width': '100px'}}
      options={options}
      isSearchable={true}
      name="cityId"
       id="cityId"
      />
      </div>
      </div>
      )}

      <div className="mb-3 row">
      <label  className="col-sm-2 col-form-label">Location</label>
      <div className="col-sm-10">
      <textarea className="form-control" name="landmark" id="landmark"></textarea>
      </div></div>

      <div className="mb-3 row">
      <label  className="col-sm-2 col-form-label">Order</label>
      <div className="col-sm-10">
      <textarea className="form-control" name="specialInstructions" id="specialInstructions"></textarea>
      </div></div>

      <div className="mb-3 row">
      <label className="col-sm-2 col-form-label">USD</label>
      <div className="col-sm-10">
      <input type="number" step="any" name="usd" id="usd" className="form-control"></input>
      </div>
      </div>


      <div className="mb-3 row">
      <label className="col-sm-2 col-form-label">LBP</label>
      <div className="col-sm-10">
      <input type="number" step="any" name="lbp" id="lbp" className="form-control"></input>
      </div>
      </div>

      </div>
      
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={toggleModal}>
          Close
        </button>
        <input type="submit" className="btn btn-primary" value="Save Data"></input>
      </div>
      </form>
      </Modal.Body>
      </Modal>
      )
}


export default AdminOrderAdd;