import React, {  useState, useEffect, createContext} from 'react';
import { NavLink } from 'react-router-dom';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { api } from "../dynamic/api";

import toast from 'react-hot-toast';
import Menu from '../menu/menu';

export const DataContext = createContext();

function GetData (){


    const [categoryData, setCategoryData] = useState(null);
    const [refCategory, setRefCategory] = useState(false);

    const [productData, setProductData] = useState(null);
    const [refProduct, setRefProduct] = useState(false);

    const [settingsData, setSettingsData] = useState(null);
    const [refSettings, setRefSettings] = useState(false);

    const [cityData, setCityData] = useState(null);

    const refreshCategory = () => {
      setRefCategory(!refCategory)
    }

    const refreshProduct = () => {
      setRefProduct(!refProduct)
    }

    const refreshSettings = () => {
      setRefSettings(!refSettings)
    }



    useEffect(() => {
      getCategory();
    }, [refCategory]);


    useEffect(() => {
      getProduct();
    }, [refProduct]);


    useEffect(() => {
      getSettings();
    }, [refSettings]);

    useEffect(() => {
      getCity();
    }, []);

    const getCategory = async() => {
      let data = {isActive: true};
      await api.get(`/category/getAll`, { params: data }).then((results) => {
      if(results){
      setCategoryData(results.data.data);
      }}).catch((error) => {
      toast.error(''+error); }); 
      }

    const getProduct = async() => {
      let data = {isActive: true};
      await api.get(`/product/getAll`, { params: data }).then((results) => {
      if(results){
        setProductData(results.data.data);
      }}).catch((error) => {
      toast.error(''+error); }); 
      }

        

    const getSettings = async() => {
      let data = {isActive: true};
      await api.get(`/setting/getAll`).then(response => {
        convertArrayToObject(response.data.data)
        setSettingsData(convertArrayToObject(response.data.data));
      })
      }

    const getCity = async() => {
      let data = {isActive: true};
      await api.get(`/city/getAll`).then(response => {
        setCityData(response.data.data);
      })
      }



      function convertArrayToObject(array) {
        return array.reduce((obj, item) => {
          obj[item.name] = item.value+'';
          return obj;
        }, {});
      }
    




    const checkLoad = () => {
      if(categoryData && productData && settingsData && cityData){
        return true;
      }else{
      return false;
    }
    }

  return (
    <div>
    {checkLoad() && (
    <DataContext.Provider 
    value={{
      categoryData,
      refreshCategory,
      productData,
      refreshProduct,
      settingsData,
      refreshSettings,
      cityData,
      }}>
  <Menu/>
  </DataContext.Provider>
  )}  
</div>
            
        );
    }

 
export default GetData;